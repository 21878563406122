import fileDownload from 'js-file-download';
import axios from '../Http';
export default {
  signIn: (postData) => axios.post('/admin/api/token/login', postData),
  getMe: () => axios.get('/admin/api/auth-user'),
  get: (params) => axios.get('/admin/api/users', { params }),
  getById: (id) => axios.get(`/admin/api/users/${id}`),
  deleteById: (id) => axios.delete(`/admin/api/users/${id}`),
  add: (data) => axios.post('/admin/api/users', data),
  update: (data, id) => axios.put(`/admin/api/users/${id}`, data),
  updatePassword: (data, id) =>
    axios.put(`/admin/api/users/${id}/update-password`, data),
  getBalance: (id) => axios.get(`/admin/api/users/${id}/balance`),
  export: (params) =>
    axios
      .get('admin/api/export/users', {
        responseType: 'blob',
        params: params,
      })
      .then((res) =>
        fileDownload(
          res.data,
          res.headers['content-disposition'].split('filename=')[1]
        )
      ),
};
