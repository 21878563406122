const initialState = {
  lastMessage: {
    message: {
      title: '',
      description: ''
    },
    type: null,
    date: null
  },
  items: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_NEW_NOTIFY:ERROR':
      return {
        ...state,
        lastMessage: payload
      };
    case 'NOTIFY:NEW':
      return {
        ...state,
        items: [payload, ...state.items]
      };
    default:
      return state;
  }
};
