import moment from 'moment';

const Actions = {
  showMessage: message => ({
    type: 'SET_NEW_NOTIFY:ERROR',
    payload: message
  }),
  addNew: message => ({
    type: 'NOTIFY:NEW',
    payload: message
  }),
  error: message => dispatch => {
    if (!_.isObject(message)) {
      message = {
        title: message,
        description: ''
      };
    }

    const msg = {
      message: message,
      type: 'error',
      date: moment().format('LTS')
    };
    dispatch(Actions.addNew(msg));
    dispatch(Actions.showMessage(msg));
  },
  info: message => dispatch => {
    if (!_.isObject(message)) {
      message = {
        title: message,
        description: ''
      };
    }
    const msg = {
      message: message,
      type: 'info',
      date: moment().format('LTS')
    };
    dispatch(Actions.addNew(msg));
    dispatch(Actions.showMessage(msg));
  },
  success: message => dispatch => {
    if (!_.isObject(message)) {
      message = {
        title: message,
        description: ''
      };
    }
    const msg = {
      message: message,
      type: 'success',
      date: moment().format('LTS')
    };
    dispatch(Actions.addNew(msg));
    dispatch(Actions.showMessage(msg));
  }
};

export default Actions;
