import React, { useRef, useEffect } from 'react';
import { useSnackbar, SnackBa } from 'notistack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button } from '@mui/material';

export default () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const options = {
    autoHideDuration: 3000,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    }
  };

  const showMessage = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
      ...options,
      action: key => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <HighlightOffIcon fontSize="small" />
        </div>
      )
    });
  };

  const showQuestion = (message, confirm, cancel) => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      action: key => {
        const ref = useRef(null);
        useEffect(() => {
          ref?.current && ref.current.focus();
        }, [ref]);
        return (
          <>
            <Button
              ref={ref}
              color="primary"
              variant="contained"
              style={{ marginRight: 5 }}
              onClick={() => {
                closeSnackbar(key);
                confirm();
              }}
            >
              Да
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                if (cancel) cancel();
                closeSnackbar(key);
              }}
            >
              Нет
            </Button>
          </>
        );
      }
    });
  };

  return {
    info: message => showMessage(message, 'info'),
    warning: message => showMessage(message, 'warning'),
    error: message => showMessage(message, 'error'),
    success: message => showMessage(message, 'success'),
    question: (message, callback, cancel) =>
      showQuestion(message, callback, cancel)
  };
};
