const initialState = {
  data: {
    path: '/',
    url: '/',
    isExact: true,
    params: {}
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'MATCH:SET_DATA':
      return {
        ...state,
        data: payload
      };
    default:
      return state;
  }
};
