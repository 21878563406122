import { createStore, applyMiddleware, compose } from 'redux';
import {
  createStateSyncMiddleware,
  initMessageListener,
  initStateWithPrevTab
} from 'redux-state-sync';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const config = {
  channel: 'current',
  blacklist: ['MATCH:SET_DATA'],
  broadcastChannelOption: { type: 'idb' }
};

const middleware = [thunk, createStateSyncMiddleware(config)];

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

initMessageListener(store);

export default store;
