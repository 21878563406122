import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import store from './redux/store';
import { userActions } from './redux/actions';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';

import defaultTheme from './themes/default';
window._ = require('lodash');
window.axios = require('axios');
import 'normalize.css';
import './styles/app.scss';
import ErrorBoundary from './components/ErrorBoundary';
console.log('HELLO BOOMERNAG SERVIS');
store.dispatch(userActions.fetchUserData());

render(
  <StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <ThemeProvider theme={defaultTheme}>
            <Router>
              <App />
            </Router>
          </ThemeProvider>
        </SnackbarProvider>
      </Provider>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById('root')
);
