const initialState = {
  name: ''
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'PAGE:SET_DATA':
      return {
        ...state,
        name: payload
      };
    default:
      return state;
  }
};
