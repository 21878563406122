import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

const Home = React.lazy(() => import('./pages/Home'));
const Auth = React.lazy(() => import('./pages/Auth'));

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import 'moment/locale/ru';
import Spinner from './components/Spinner';
import notify from './redux/reducers/notify';
import useNotification from './hooks/useNotification';
import { LocalizationProvider } from '@mui/x-date-pickers';

moment.locale('ru');
moment.tz.setDefault('Europe/Moscow');
const App = (props) => {
  const notify = useNotification();
  const { isAuth, notifications } = props;

  useEffect(() => {
    if (notifications.lastMessage?.message?.title !== '') {
      switch (notifications.lastMessage?.type) {
        case 'error':
          notify.error(notifications.lastMessage.message.title);
          break;
        case 'info':
          notify.info(notifications.lastMessage.message.title);
          break;
        case 'success':
          notify.success(notifications.lastMessage.message.title);
          break;
        default:
          notify.warning(notifications.lastMessage.message.title);
          break;
      }
    }
  }, [notifications.lastMessage]);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Switch>
        <Suspense fallback={<Spinner />}>
          <Route
            exact
            path={['/signin', '/signup']}
            render={() => (isAuth ? <Redirect to="/" /> : <Auth />)}
          />
          <Route path="/*" exact render={() => (isAuth ? <Home /> : null)} />
        </Suspense>
      </Switch>
    </LocalizationProvider>
  );
};

export default connect(({ user, notify }) => ({
  isAuth: user.isAuth,
  notifications: notify,
}))(App);
