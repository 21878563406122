import userApi from '../../utils/api/users';
import notifyActions from './notify';
const Actions = {
  setUserData: (data) => ({
    type: 'USER:SET_DATA',
    payload: data,
  }),
  setIsAuth: (bool) => ({
    type: 'USER:SET_IS_AUTH',
    payload: bool,
  }),
  userLogOut: () => (dispatch) => {
    dispatch(Actions.setIsAuth(false));
    delete window.localStorage.token;
  },
  fetchUserData: () => (dispatch) => {
    userApi
      .getMe()
      .then(({ data }) => {
        dispatch(Actions.setUserData(data));
        dispatch(Actions.setIsAuth(true));
      })
      .catch((err) => {
        dispatch(Actions.setIsAuth(false));
        delete window.localStorage.token;
      });
  },
  fetchUserLogin: (postData) => (dispatch) => {
    return userApi
      .signIn(postData)
      .then(({ data }) => {
        const { token } = data;
        console.log(window, data);
        // window.localStorage['token'] = token;
        localStorage.setItem('token', token);
        window.axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${token}`;
        // console.log(window.axios);
        dispatch(Actions.fetchUserData());
        return data;
      })
      .catch((error) => {
        if (error.response.data.status === 'uncorfirmed') {
          console.log({
            title: 'Подвердите аккаунт!',
            text: 'Аккаунт пользователя не подтвержден',
            type: 'info',
          });
        } else {
          dispatch(notifyActions.error('Ошибка при авторизации'));
          console.log({
            title: 'Ошибка при авторизации',
            text: 'Неверный логин или пароль',
            type: 'error',
          });
        }
      });
  },
};

export default Actions;
