/* eslint-disable no-console */
import axios from 'axios';
import { notifyActions } from '../redux/actions';
import store from '../redux/store';

let API_URL;

if (process.env.NODE_ENV === 'production') {
  API_URL = 'https://boomerang.boomin.ru';
} else {
  API_URL = 'http://backend.boomerang.server.usc';
  console.log(process.env, 'http://backend.boomerang.server.usc');
}

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Authorization'] =
  'Bearer ' + window.localStorage.token;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      if (window.location.pathname !== '/signup') {
        store.dispatch(notifyActions.error('Вы не авторизованны'));
        window.location.pathname = '/signup';
      }
    }
    if (error.response.status === 500) {
      store.dispatch(notifyActions.error('Ошибка на сервере'));
    }
    if (error.response.status === 403) {
      console.log(error.response);
      store.dispatch(
        notifyActions.error({
          description: error.response.data.message,
          title: 'Ошибка доступа',
        })
      );
    }
    if (error.response.status === 409) {
      store.dispatch(
        notifyActions.error({
          title: `${error.response.data.info[0]}-${error.response.data.info[1]} Ошибка при выполнении запроса`,
          description: error.response.data.info[2],
        })
      );
    }
    return Promise.reject(error);
  }
);

export default axios;
